import React from 'react'
import Sidebar from '@components/dec/sidebar';
import Layout from '@components/layout/layout'
import * as stylesContent from '@styles/layout/content.module.css'
import Seo from '@components/setting-site/seo'

const SignDec = () => (
  <Layout>
    <Seo title="Developers Stupendo | Dec" />
    <div className={stylesContent.body}>
      <div className={stylesContent.container}>
        <Sidebar />
      </div>
      <embed
        src="https://decfiles.s3.us-east-1.amazonaws.com/dec/api_firmado_de_procesos.pdf"
        className={stylesContent.pdf}
      />
    </div>
  </Layout>
)

export default SignDec
